import React from "react";
import {
  StyledFooterDisclaimer
} from "./styles";

import { Text } from "../Text";
import { Box } from "../Box";

const FooterDisclaimer: React.FC<React.PropsWithChildren> = ({
  ...props
}) => {
  // const isMounted = useIsMounted();
  return (
    <StyledFooterDisclaimer
      data-theme="dark"
      p={["10px 10px 62px 10px", null, "10px 10px 10px 10px"]}
      position="relative"

      {...props}
      justifyContent="center"
      background="transparent"
      borderTop="1px solid white"
    >
      <Box maxWidth={1000} mx='center'>

        <Text fontSize={12} fontWeight={500} textAlign="center">

          <Text as="span" fontSize={12} fontWeight={900}>
            Disclaimer: {" "}
          </Text>
          The content of any material provided or published by GemLaunch does not represent, in any way, financial advice and is not an investment recommendation. We are not responsible and do not accept liability for any loss of assets to any person or persons acting upon information present in any material associated with GemLaunch.
        </Text>
      </Box>

    </StyledFooterDisclaimer>
  );
};

export default FooterDisclaimer;
