import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";



const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (

    <Svg width="14" height="19" viewBox="0 0 14 19" fill="transparent !important" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1170_7914)">
        <path d="M10.8523 5.66836L7.32 2.1366C7.27864 2.09342 7.22897 2.05905 7.17397 2.03558C7.11898 2.0121 7.0598 2 7 2C6.9402 2 6.88102 2.0121 6.82603 2.03558C6.77103 2.05905 6.72136 2.09342 6.68 2.1366L3.14769 5.66836M10.8523 14.3316L7.32 17.8634C7.27864 17.9066 7.22897 17.9409 7.17397 17.9644C7.11898 17.9879 7.0598 18 7 18C6.9402 18 6.88102 17.9879 6.82603 17.9644C6.77103 17.9409 6.72136 17.9066 6.68 17.8634L3.14769 14.3316M-1 10H15" stroke="#090909" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1170_7914">
          <rect width="14" height="18" transform="translate(0 0.987751)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;