import { vars } from "@gemlaunch/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";




const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="250" height="36" viewBox="0 0 250 36"
      {...props}
    >
      <g clipPath="url(#clip0_565_6895)">
        <path d="M15.0745 12.6894C18.0122 12.6894 20.4786 13.6473 22.6307 15.7493L25.8399 12.6894C23.2545 9.82012 19.4216 8.33203 14.8412 8.33203C6.26595 8.32738 0 14.103 0 22.0923C0 30.0815 6.26595 35.8572 14.7602 35.8572C18.636 35.8572 22.6307 34.7085 25.4875 32.4531V21.7853H14.584V26.538H20.6738V30.1187C18.9121 31.1138 16.9933 31.4952 15.0364 31.4952C9.24179 31.4952 5.13274 27.5192 5.13274 22.0923C5.13274 16.6654 9.24179 12.6847 15.0745 12.6847V12.6894Z" fill="white" />
        <path d="M40.8001 14.8285C34.577 14.8285 29.9966 19.1858 29.9966 25.2684C29.9966 31.351 34.5389 35.7455 41.5476 35.7455C45.152 35.7455 48.085 34.5969 49.9657 32.4206L47.3422 29.4769C45.8519 30.9697 44.014 31.6951 41.6667 31.6951C38.0242 31.6951 35.4817 29.7839 34.896 26.803H51.2989C51.337 26.3426 51.3751 25.7706 51.3751 25.3893C51.3751 19.0045 46.9137 14.8378 40.8049 14.8378L40.8001 14.8285ZM34.8484 23.6222C35.3198 20.6414 37.629 18.651 40.8001 18.651C43.9712 18.651 46.3185 20.6786 46.7137 23.6222H34.8532H34.8484Z" fill="white" />
        <path d="M82.4572 14.8285C79.129 14.8285 76.3102 16.1259 74.6295 18.2325C73.182 15.9399 70.6347 14.8285 67.5827 14.8285C64.7259 14.8285 62.2976 15.8237 60.7311 17.6559V15.0564H56.0745V35.4758H60.9691V25.115C60.9691 21.0645 63.1594 18.9998 66.4114 18.9998C69.3872 18.9998 71.1108 20.7576 71.1108 24.3895V35.4805H76.0055V25.1196C76.0055 21.0692 78.2386 19.0045 81.4478 19.0045C84.4236 19.0045 86.1472 20.7623 86.1472 24.3942V35.4851H91.0419V23.785C91.0419 17.628 87.5185 14.8378 82.4667 14.8378L82.4572 14.8285Z" fill="white" />
        <path d="M102.193 7.10435H97.2983V35.4758H102.193V7.10435Z" fill="white" />
        <path d="M116.601 14.8285C113.235 14.8285 109.983 15.6702 107.754 17.3536L109.673 20.832C111.24 19.5718 113.668 18.8045 116.015 18.8045C119.501 18.8045 121.219 20.4507 121.219 23.2409V23.5478H115.815C109.473 23.5478 107.126 26.2264 107.126 29.6257C107.126 33.0251 110.14 35.7455 114.915 35.7455C118.048 35.7455 120.319 34.7504 121.491 32.9926V35.4805H126.109V23.5153C126.109 17.628 122.624 14.8378 116.596 14.8378L116.601 14.8285ZM121.219 29.091C120.396 31.1929 118.324 32.2625 115.934 32.2625C113.43 32.2625 111.939 31.1557 111.939 29.4351C111.939 27.9423 112.839 26.7193 116.168 26.7193H121.219V29.091Z" fill="white" />
        <path d="M147.726 25.38C147.726 29.4723 145.417 31.537 142.012 31.537C138.918 31.537 137.118 29.7792 137.118 26.0683V15.0564H132.223V26.7193C132.223 32.8763 135.866 35.7409 141.227 35.7409C143.927 35.7409 146.393 34.7457 147.959 32.8717V35.4712H152.621V15.0517H147.726V25.3754V25.38Z" fill="white" />
        <path d="M170.909 14.8285C167.857 14.8285 165.31 15.8237 163.705 17.6977V15.061H159.044V35.4805H163.938V25.1568C163.938 21.0645 166.286 18.9998 169.771 18.9998C172.904 18.9998 174.742 20.7576 174.742 24.3895V35.4805H179.637V23.7803C179.637 17.6233 175.918 14.8331 170.909 14.8331V14.8285Z" fill="white" />
        <path d="M195.616 18.8836C197.692 18.8836 199.568 19.7625 200.825 21.7109L204.581 19.5718C202.977 16.4747 199.725 14.8285 195.654 14.8285C189.036 14.8285 184.341 19.1486 184.341 25.2684C184.341 31.3882 189.036 35.7455 195.654 35.7455C199.725 35.7455 202.972 34.0249 204.581 30.965L200.825 28.8259C199.572 30.7744 197.692 31.6533 195.616 31.6533C192.016 31.6533 189.274 29.2444 189.274 25.2684C189.274 21.2924 192.016 18.8836 195.616 18.8836Z" fill="white" />
        <path d="M220.48 14.8285C217.58 14.8285 215.114 15.7446 213.509 17.428V7.10435H208.614V35.4758H213.509V25.1522C213.509 21.0599 215.856 18.9952 219.342 18.9952C222.475 18.9952 224.312 20.753 224.312 24.3849V35.4758H229.207V23.7757C229.207 17.6187 225.489 14.8285 220.475 14.8285H220.48Z" fill="white" />
        <path d="M235.625 0.142851C235.625 4.97451 231.616 8.88541 226.674 8.88541C231.621 8.88541 235.625 12.801 235.625 17.628C235.625 12.7963 239.634 8.88541 244.577 8.88541C239.63 8.88541 235.625 4.96986 235.625 0.142851Z" fill="#13D7D2" />
        <path d="M244.582 16.8002C244.582 19.7253 242.153 22.0923 239.163 22.0923C242.158 22.0923 244.582 24.4639 244.582 27.3843C244.582 24.4593 247.01 22.0923 250 22.0923C247.005 22.0923 244.582 19.7206 244.582 16.8002Z" fill="#13D7D2" />
      </g>
      <defs>
        <clipPath id="clip0_565_6895">
          <rect width="250" height="35.7143" fill="white" transform="translate(0 0.142851)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Logo;
