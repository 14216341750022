import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | GemLaunch',
  defaultTitle: 'GemLaunch',
  description:
    'Cheaper and faster than Uniswap? Discover GemLaunch, the leading DEX on BNB Smart Chain (BSC) and Ethereum.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@GemLaunch',
    site: '@GemLaunch',
  },
  openGraph: {
    title: 'GemLaunch - A next evolution DeFi exchange on BNB Smart Chain (BSC) and Ethereum',
    description: 'The most popular AMM on BSC and Ethereum by user count!',
    images: [{ url: 'https://res.cloudinary.com/dgsb5totw/image/upload/v1714471942/wukwkz8zmbzs8fpgp3vn.png' }],
  },
}
