import memoize from 'lodash/memoize'
import { ContextApi } from '@gemlaunch/localization'
import { PageMeta } from './types'
import { ASSET_CDN } from './endpoints'

const openGraphImage = {
  hero: 'https://res.cloudinary.com/dgsb5totw/image/upload/v1714486905/DEX_t4cnc5.jpg',
  swap: 'https://res.cloudinary.com/dgsb5totw/image/upload/v1714486905/Swap_xtaopp.jpg',
  liquidity: 'https://res.cloudinary.com/dgsb5totw/image/upload/v1714486905/Liquidity_fwa0gr.jpg',
}
export const DEFAULT_META: PageMeta = {
  title: 'GemLaunch',
  description: 'The most popular AMM on BSC and Ethereum by user count!.',
  image: openGraphImage.hero,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': { basePath: true, title: t('Exchange'), image: openGraphImage.swap },
      '/add': { basePath: true, title: t('Add Liquidity'), image: openGraphImage.hero },
      '/remove': { basePath: true, title: t('Remove Liquidity'), image: openGraphImage.liquidity },
      '/liquidity': { title: t('Liquidity'), image: openGraphImage.liquidity },
      '/find': { title: t('Import Pool'), image: openGraphImage.hero },
    },
    defaultTitleSuffix: t('GemLaunch'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
