


import { vars } from "@gemlaunch/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";




const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (<Svg width="37" height="43" viewBox="0 0 37 43" {...props}>
    <path d="M14.1986 0C14.1986 7.62666 7.83946 13.7999 0 13.7999C7.84701 13.7999 14.1986 19.9805 14.1986 27.5998C14.1986 19.9732 20.5578 13.7999 28.3972 13.7999C20.5502 13.7999 14.1986 7.61932 14.1986 0Z" fill="#13D7D2" />
    <path d="M28.4052 26.2932C28.4052 30.9103 24.5535 34.6466 19.8105 34.6466C24.561 34.6466 28.4052 38.3902 28.4052 42.9999C28.4052 38.3828 32.257 34.6466 37 34.6466C32.2495 34.6466 28.4052 30.903 28.4052 26.2932Z" fill="#13D7D2" />
  </Svg>);
};

export default Logo;
